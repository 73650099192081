var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalData.showModal)?_c('BaseModal',[_c('CourseAddFileModal',{attrs:{"courseId":+_vm.courseId},on:{"close":_vm.closeCreateFileModal,"submit":_vm.submitCreateFileModal}})],1):_vm._e(),(_vm.showFilePreview)?_c('BaseModal',{attrs:{"maxWidth":1500}},[_c('CourseViewFileModal',{attrs:{"filePreview":_vm.filePreview},on:{"close":_vm.closeViewFileModal}})],1):_vm._e(),_c('v-form',[_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.courseFiles,"search":_vm.search,"no-data-text":"Ingen filer"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"},attrs:{"data-cy":"participantsHeader"}},[_vm._v(" Dokumenter ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i rader","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Legg til fil","icon":"mdi-plus"},on:{"handleClick":_vm.showCreateFileModal}})]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.viewFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Vis dokument")])]),(!_vm.isCourseDone)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Slett dokument")])]):_vm._e()]}},{key:"item.description",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(value && value.length > 20 ? ((value.substring(0, 20)) + "...") : value))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"item.inserted",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.participantRole",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.isVocationalSchool && value === "Student" ? "Deltaker" : value)+" ")]}}],null,true)},[_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }