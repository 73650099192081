









































import { api } from "@/api/api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateMaxLength, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

export interface LocalFileData {
  file?: File;
  selectedCategory: string;
  fileDescription: string;
}

const initialFileData: () => LocalFileData = () => ({
  file: undefined,
  selectedCategory: "",
  fileDescription: "",
});

const FILE_TYPE_WHITELIST = ["application/pdf", "image/png", "image/jpeg"];

export default defineComponent({
  name: "CourseAddFileModal",
  emits: ["close"],
  components: { BaseModalForm },
  props: {
    courseId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const courseFileCategories = ref<string[]>();
    const fileData = ref(initialFileData());
    const modalType = ref<ModalType>(ModalType.Add);

    const fetchCourseFileCategories = async () => {
      const response = await api.file.getFileDefaultOption();
      if (!response.data.categories) {
        return;
      }
      courseFileCategories.value = response.data.categories;
    };

    const onSubmit = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }
      await uploadCourseFile();
    };

    const uploadCourseFile = async () => {
      if (!fileData.value.file) {
        return;
      }

      const type = fileData.value.file.type;
      if (!FILE_TYPE_WHITELIST.includes(type)) {
        openNotification(
          store,
          NotificationItemType.Error,
          "Ugyldig filformat, vennligst velg en fil av typen PDF, PNG eller JPEG"
        );
        return;
      }

      const uploadFile = new File([fileData.value.file], fileData.value.file.name, {
        type,
      });
      const data = {
        File: uploadFile,
        Description: fileData.value.fileDescription,
        Category: fileData.value.selectedCategory,
      };
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.file.createCourseFileAsync(props.courseId, data);
        emit("submit");
      });
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseFileCategories();
      });
    });

    return {
      fileData,
      validateMaxLength,
      onSubmit,
      modalType,
      validateNotEmpty,
      courseFileCategories,
    };
  },
});
