












import CourseHeader from "@/components/course/details/CourseHeader.vue";
import CourseFiles from "@/components/course/details/files/CourseFiles.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CourseFilePage",
  components: { CourseHeader, CourseFiles, BaseSheet },
  setup() {
    const store = useStore<StoreState>();
    return { course: computed(() => store.state.courses.course) };
  },
});
