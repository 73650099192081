


























































import CourseAddFileModal from "@/components/course/details/files/CourseAddFileModal.vue";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import { api, binaryApi } from "@/api/api";
import { ApiGetCourseRelatedFileDto, ApiGetCourseParticipantKursAdminDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref, computed } from "@vue/composition-api";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { OwnerType } from "@/shared/enums/ownerType.enum";
import { FileViewModel, getInitialFilePreview } from "@/shared/helpers/fileViewHelpers";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { FileCategoryType } from "@/shared/enums/FileCategoryType.enum";

export default defineComponent({
  name: "CourseFiles",
  components: {
    BaseTableFiltered,
    CourseAddFileModal,
    BaseModal,
    BaseTooltipIconButton,
    CourseViewFileModal,
  },
  props: {
    courseId: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
    },
    isCourseDone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const courseFiles = ref<FileViewModel[]>();
    const courseParticipants = ref<Record<number, ApiGetCourseParticipantKursAdminDto>>({});
    const modalData = ref(getInitialModalData());
    const search = ref<string>();
    const showFilePreview = ref(false);
    const filePreview = ref(getInitialFilePreview());

    const showCreateFileModal = useOpenModal(ModalType.Add, "fil", modalData);

    const closeCreateFileModal = () => {
      modalData.value.showModal = false;
    };

    const submitCreateFileModal = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseFiles();
        modalData.value.showModal = false;
      });
    };

    const fetchCourseFiles = async () => {
      const courseFilesResponse = (await api.file.getCourseFilesAsync(+props.courseId)).data;

      courseFiles.value = courseFilesResponse
        .filter((courseFile) => courseFile.category !== FileCategoryType.Regulation)
        .map((courseFile) => {
          const fileOwnerUserId =
            courseFile.fileOwners?.find((fileOwner) => fileOwner.ownerType === OwnerType.User)?.ownerId ?? 0;
          return {
            ...courseFile,
            insertedByFullName: courseFile.insertedByUserFullName ?? "",
            participantUserFullName: courseParticipants.value[fileOwnerUserId]?.userFullName ?? "ADMIN",
            participantStatus: courseParticipants.value[fileOwnerUserId]?.status ?? "",
            participantRole: courseParticipants.value[fileOwnerUserId]?.roleNames?.join(", "),
          };
        });
    };

    const fetchCourseParticipants = async () => {
      const courseParticipantsResponse = (await api.course.getCourseParticipantsByCourseIdAsync(+props.courseId)).data;

      courseParticipants.value = courseParticipantsResponse.reduce<Record<number, ApiGetCourseParticipantKursAdminDto>>(
        (recordOutput, courseParticipant) => ({ ...recordOutput, [courseParticipant.userId]: courseParticipant }),
        {}
      );
    };

    const deleteFile = async (item: ApiGetCourseRelatedFileDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.file.deleteFileAsync(item.id);
        } catch {
          openNotification(
            store,
            NotificationItemType.Error,
            `En feil oppsto ved sletting av ${item.originalFileName}.`
          );
        } finally {
          await fetchCourseFiles();
        }
      });
    };

    const viewFile = async (item: ApiGetCourseRelatedFileDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!item.originalFileName) {
          return;
        }
        try {
          const courseFile = await binaryApi.file.downloadFileAsync(item.id, { format: "blob" });
          const blob = new Blob([courseFile.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          filePreview.value = {
            name: item.originalFileName,
            mimeType: item.mimeType,
            source: url,
          };
          showFilePreview.value = true;
        } catch {
          openNotification(
            store,
            NotificationItemType.Error,
            `En feil oppsto ved visning av ${item.originalFileName}.`
          );
        }
      });
    };

    const closeViewFileModal = () => {
      showFilePreview.value = false;
      filePreview.value = getInitialFilePreview();
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseParticipants();
        await fetchCourseFiles();
      });

      if (props.searchQuery) {
        search.value = props.searchQuery;
      }
    });

    return {
      isVocationalSchool: computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId)),
      courseFiles,
      headers,
      validateNotEmpty,
      deleteFile,
      closeCreateFileModal,
      submitCreateFileModal,
      showCreateFileModal,
      search,
      modalData,
      showFilePreview,
      viewFile,
      closeViewFileModal,
      filePreview,
      formatDateTime,
    };
  },
});
const headers = [
  {
    text: "Filnavn",
    value: "originalFileName",
  },
  {
    text: "Kategori",
    value: "category",
  },
  {
    text: "Beskrivelse",
    value: "description",
  },
  {
    text: "Opplastningsdato",
    value: "inserted",
  },
  {
    text: "Lastet opp av",
    value: "insertedByFullName",
  },
  {
    text: "Bruker",
    value: "participantUserFullName",
  },
  {
    text: "Deltakerstatus",
    value: "participantStatus",
  },
  {
    text: "Rolle",
    value: "participantRole",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
];
